import Cookies from "universal-cookie";

import {
  API_EDIT_URL_PATH,
  API_RENDERING_PATH,
  COOKIE_ADMIN,
  COOKIE_USERNAME,
  HEADER_IS_ADMIN,
  HEADER_X_FORWARDED_USER,
  MAIN_PAGE_TITLE,
} from "../../config/constants";
import UrlHelper from "../../helpers/url-helper";
import HttpClient from "../clients/HttpClient";

export enum HttpResponse {
  Success = "SUCCESS",
  NotFound = "NOT_FOUND",
  Forbidden = "FORBIDDEN",
  InternalServerError = "INTERNAL_SERVER_ERROR",
  Redirect = "REDIRECT",
}

export const STATUS_CODE_TO_HTTP_RESPONSE_MAP: Record<number, HttpResponse> = {
  404: HttpResponse.NotFound,
  400: HttpResponse.NotFound,
  403: HttpResponse.Forbidden,
};

export default class RenderingHelper {
  static basePath: string = `${UrlHelper.getApiUrl(UrlHelper.getStage())}${API_RENDERING_PATH}`;

  static getData = async (path: string, language: string): Promise<[string, HttpResponse]> => {
    const url = `${this.basePath}${path}?language=${language}`;
    try {
      const response = await HttpClient.getData(url);
      const data = await response.text();
      const headers = await response.headers;
      if (headers?.get(HEADER_IS_ADMIN)) {
        const cookies = new Cookies();
        cookies.set(COOKIE_ADMIN, headers?.get(HEADER_IS_ADMIN), { path: "/" });
        cookies.set(COOKIE_USERNAME, headers?.get(HEADER_X_FORWARDED_USER), { path: "/" });
      }

      if (response.redirected === true) {
        // update the url in the browser
        window.history.pushState("", MAIN_PAGE_TITLE, this.extractPathFromUrl(response.url));
      }

      if (response.ok) {
        //status in the range 200-299
        return this.handle2xxResponse(data);
      } else if (response.status >= 400 && response.status < 500) {
        return this.handle4xxResponse(response.status);
      } else {
        return this.handle5xxResponse();
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  /**
   * The function make a call to the api to retrieve the edit url.
   * It returns the edit url.
   */
  static getEditButtonUrl = async (path: string, language: string): Promise<string> => {
    const apiUrl = UrlHelper.getApiUrl(UrlHelper.getStage());
    const url = `${apiUrl}${API_EDIT_URL_PATH}${path}?language=${language}`;
    try {
      const response = await HttpClient.getData(url);
      const data = await response.text();

      if (response.ok) {
        //status in the range 200-299
        return data;
      } else {
        return "";
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  private static extractPathFromUrl(responseUrl: string): string {
    const url = new URL(responseUrl);
    return url.pathname.replace(`${API_RENDERING_PATH}`, "");
  }

  private static handle2xxResponse(data: any): [string, HttpResponse] {
    if (!data) {
      console.error("Lego page retrieved from API is empty!");
    }
    return [data, HttpResponse.Success];
  }

  private static handle4xxResponse(statusCode: number): [string, HttpResponse] {
    console.error("Page not found in Lego!");
    return ["", STATUS_CODE_TO_HTTP_RESPONSE_MAP[statusCode] ?? HttpResponse.NotFound];
  }

  private static handle5xxResponse(): [string, HttpResponse] {
    console.error("Internal server error when tried to fetch the Lego page!");
    return ["", HttpResponse.InternalServerError];
  }
}
