import "@amzn/awsui-global-styles";
import "common/styles/style";

import App from "App";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { AppContextProvider } from "./AppContext";

const arbManifest = require("../build/translations/arbManifest");
import { createAsyncMessageBundleManager, LocalizationContextBuilder } from "@amzn/arb-tools";
import { MbmProvider } from "@amzn/react-arb-tools";
import { DEFAULT_LOCALE, LOCALE_KEY } from "config/constants";

const localizationContextProvider = () => {
  const browserLocale = window.navigator.language; // web brower's locale based on user's OS setting
  const storedLocale = localStorage.getItem(LOCALE_KEY);

  return new LocalizationContextBuilder()
    .withDefaultLocale(DEFAULT_LOCALE)
    .withLocale(storedLocale ?? browserLocale)
    .build();
};

const mbm = createAsyncMessageBundleManager({
  arbManifest,
  localizationContextProvider,
  urlPrefix: "translations",
});

document.body.style.margin = "0";
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppContextProvider>
        <MbmProvider mbm={mbm}>
          <App />
        </MbmProvider>
      </AppContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("app")
);
