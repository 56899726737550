import { DEFAULT_LOCALE, LOCALE_KEY } from "config/constants";
import UrlHelper from "helpers/url-helper";
import HttpClient from "lib/clients/HttpClient";
import { IArticleMetadata, MetadataDateType, MetadataType } from "lib/interfaces/metadata";
import { useEffect, useState } from "react";

export type MetadataByCategoriesType = Record<string, IArticleMetadata[]>;

export interface IUseMetadataArticlesFilterParams {
  filter?: MetadataType | MetadataDateType;
  filteringList?: string[];
}

const useMetadataArticlesFilter = ({ filter, filteringList }: IUseMetadataArticlesFilterParams) => {
  const [metadataByCategory, setMetadataByCategory] = useState<MetadataByCategoriesType>({});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const language: string = window.localStorage.getItem(LOCALE_KEY) || DEFAULT_LOCALE;

  const metadataRetrievalUrl = UrlHelper.getMetadataRetrievalUrl({ filter, filteringList, language: language });

  const fetchArticles = async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const response = await HttpClient.getData(metadataRetrievalUrl);
      const data = await response.json();

      if (data.errorMessage) {
        throw new Error(data.errorMessage);
      }

      setMetadataByCategory(data);
    } catch (error) {
      setMetadataByCategory({});
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  return { metadataByCategory, isError, isLoading };
};

export default useMetadataArticlesFilter;
