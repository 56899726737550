import { Container, Header } from "@amzn/awsui-components-react";
import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import React from "react";

interface NotFoundPageProps extends WithBundleProps {}

const NotFoundPage = ({ bundle }: NotFoundPageProps) => {
  return (
    <Container header={<Header variant="h2">{bundle.getMessage("header-text")}</Header>}>
      {bundle.getMessage("error-text")}
    </Container>
  );
};

export default withBundle("com.a2z.it.kb.NotFoundPage")(NotFoundPage);
