var endpointMap = {
	ar: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-ar.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-ar.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-ar.json",
		"com.a2z.it.kb.EditButton": "translation-ar.json",
		"com.a2z.it.kb.EditedDate": "translation-ar.json",
		"com.a2z.it.kb.Footer": "translation-ar.json",
		"com.a2z.it.kb.Header": "translation-ar.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-ar.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-ar.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-ar.json",
		"com.a2z.it.kb.LanguagePicker": "translation-ar.json",
		"com.a2z.it.kb.NotFoundPage": "translation-ar.json",
		"com.a2z.it.kb.RelatedArticles": "translation-ar.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-ar.json",
		"com.a2z.it.kb.SearchBar": "translation-ar.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-ar.json",
		"com.a2z.it.kb.Sitemap": "translation-ar.json"
	},
	"ar-AE": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-ar.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-ar.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-ar.json",
		"com.a2z.it.kb.EditButton": "translation-ar.json",
		"com.a2z.it.kb.EditedDate": "translation-ar.json",
		"com.a2z.it.kb.Footer": "translation-ar.json",
		"com.a2z.it.kb.Header": "translation-ar.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-ar.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-ar.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-ar.json",
		"com.a2z.it.kb.LanguagePicker": "translation-ar.json",
		"com.a2z.it.kb.NotFoundPage": "translation-ar.json",
		"com.a2z.it.kb.RelatedArticles": "translation-ar.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-ar.json",
		"com.a2z.it.kb.SearchBar": "translation-ar.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-ar.json",
		"com.a2z.it.kb.Sitemap": "translation-ar.json"
	},
	cs: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-cs.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-cs.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-cs.json",
		"com.a2z.it.kb.EditButton": "translation-cs.json",
		"com.a2z.it.kb.EditedDate": "translation-cs.json",
		"com.a2z.it.kb.Footer": "translation-cs.json",
		"com.a2z.it.kb.Header": "translation-cs.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-cs.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-cs.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-cs.json",
		"com.a2z.it.kb.LanguagePicker": "translation-cs.json",
		"com.a2z.it.kb.NotFoundPage": "translation-cs.json",
		"com.a2z.it.kb.RelatedArticles": "translation-cs.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-cs.json",
		"com.a2z.it.kb.SearchBar": "translation-cs.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-cs.json",
		"com.a2z.it.kb.Sitemap": "translation-cs.json"
	},
	"cs-CZ": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-cs.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-cs.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-cs.json",
		"com.a2z.it.kb.EditButton": "translation-cs.json",
		"com.a2z.it.kb.EditedDate": "translation-cs.json",
		"com.a2z.it.kb.Footer": "translation-cs.json",
		"com.a2z.it.kb.Header": "translation-cs.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-cs.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-cs.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-cs.json",
		"com.a2z.it.kb.LanguagePicker": "translation-cs.json",
		"com.a2z.it.kb.NotFoundPage": "translation-cs.json",
		"com.a2z.it.kb.RelatedArticles": "translation-cs.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-cs.json",
		"com.a2z.it.kb.SearchBar": "translation-cs.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-cs.json",
		"com.a2z.it.kb.Sitemap": "translation-cs.json"
	},
	de: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-de.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-de.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-de.json",
		"com.a2z.it.kb.EditButton": "translation-de.json",
		"com.a2z.it.kb.EditedDate": "translation-de.json",
		"com.a2z.it.kb.Footer": "translation-de.json",
		"com.a2z.it.kb.Header": "translation-de.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-de.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-de.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-de.json",
		"com.a2z.it.kb.LanguagePicker": "translation-de.json",
		"com.a2z.it.kb.NotFoundPage": "translation-de.json",
		"com.a2z.it.kb.RelatedArticles": "translation-de.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-de.json",
		"com.a2z.it.kb.SearchBar": "translation-de.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-de.json",
		"com.a2z.it.kb.Sitemap": "translation-de.json"
	},
	"de-DE": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-de.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-de.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-de.json",
		"com.a2z.it.kb.EditButton": "translation-de.json",
		"com.a2z.it.kb.EditedDate": "translation-de.json",
		"com.a2z.it.kb.Footer": "translation-de.json",
		"com.a2z.it.kb.Header": "translation-de.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-de.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-de.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-de.json",
		"com.a2z.it.kb.LanguagePicker": "translation-de.json",
		"com.a2z.it.kb.NotFoundPage": "translation-de.json",
		"com.a2z.it.kb.RelatedArticles": "translation-de.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-de.json",
		"com.a2z.it.kb.SearchBar": "translation-de.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-de.json",
		"com.a2z.it.kb.Sitemap": "translation-de.json"
	},
	en: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-en.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-en.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-en.json",
		"com.a2z.it.kb.EditButton": "translation-en.json",
		"com.a2z.it.kb.EditedDate": "translation-en.json",
		"com.a2z.it.kb.Footer": "translation-en.json",
		"com.a2z.it.kb.Header": "translation-en.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-en.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-en.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-en.json",
		"com.a2z.it.kb.LanguagePicker": "translation-en.json",
		"com.a2z.it.kb.NotFoundPage": "translation-en.json",
		"com.a2z.it.kb.RelatedArticles": "translation-en.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-en.json",
		"com.a2z.it.kb.SearchBar": "translation-en.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-en.json",
		"com.a2z.it.kb.Sitemap": "translation-en.json"
	},
	"en-US": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-en.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-en.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-en.json",
		"com.a2z.it.kb.EditButton": "translation-en.json",
		"com.a2z.it.kb.EditedDate": "translation-en.json",
		"com.a2z.it.kb.Footer": "translation-en.json",
		"com.a2z.it.kb.Header": "translation-en.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-en.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-en.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-en.json",
		"com.a2z.it.kb.LanguagePicker": "translation-en.json",
		"com.a2z.it.kb.NotFoundPage": "translation-en.json",
		"com.a2z.it.kb.RelatedArticles": "translation-en.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-en.json",
		"com.a2z.it.kb.SearchBar": "translation-en.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-en.json",
		"com.a2z.it.kb.Sitemap": "translation-en.json"
	},
	es: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-es.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-es.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-es.json",
		"com.a2z.it.kb.EditButton": "translation-es.json",
		"com.a2z.it.kb.EditedDate": "translation-es.json",
		"com.a2z.it.kb.Footer": "translation-es.json",
		"com.a2z.it.kb.Header": "translation-es.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-es.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-es.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-es.json",
		"com.a2z.it.kb.LanguagePicker": "translation-es.json",
		"com.a2z.it.kb.NotFoundPage": "translation-es.json",
		"com.a2z.it.kb.RelatedArticles": "translation-es.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-es.json",
		"com.a2z.it.kb.SearchBar": "translation-es.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-es.json",
		"com.a2z.it.kb.Sitemap": "translation-es.json"
	},
	"es-ES": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-es.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-es.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-es.json",
		"com.a2z.it.kb.EditButton": "translation-es.json",
		"com.a2z.it.kb.EditedDate": "translation-es.json",
		"com.a2z.it.kb.Footer": "translation-es.json",
		"com.a2z.it.kb.Header": "translation-es.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-es.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-es.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-es.json",
		"com.a2z.it.kb.LanguagePicker": "translation-es.json",
		"com.a2z.it.kb.NotFoundPage": "translation-es.json",
		"com.a2z.it.kb.RelatedArticles": "translation-es.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-es.json",
		"com.a2z.it.kb.SearchBar": "translation-es.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-es.json",
		"com.a2z.it.kb.Sitemap": "translation-es.json"
	},
	fr: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-fr.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-fr.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-fr.json",
		"com.a2z.it.kb.EditButton": "translation-fr.json",
		"com.a2z.it.kb.EditedDate": "translation-fr.json",
		"com.a2z.it.kb.Footer": "translation-fr.json",
		"com.a2z.it.kb.Header": "translation-fr.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-fr.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-fr.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-fr.json",
		"com.a2z.it.kb.LanguagePicker": "translation-fr.json",
		"com.a2z.it.kb.NotFoundPage": "translation-fr.json",
		"com.a2z.it.kb.RelatedArticles": "translation-fr.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-fr.json",
		"com.a2z.it.kb.SearchBar": "translation-fr.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-fr.json",
		"com.a2z.it.kb.Sitemap": "translation-fr.json"
	},
	"fr-FR": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-fr.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-fr.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-fr.json",
		"com.a2z.it.kb.EditButton": "translation-fr.json",
		"com.a2z.it.kb.EditedDate": "translation-fr.json",
		"com.a2z.it.kb.Footer": "translation-fr.json",
		"com.a2z.it.kb.Header": "translation-fr.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-fr.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-fr.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-fr.json",
		"com.a2z.it.kb.LanguagePicker": "translation-fr.json",
		"com.a2z.it.kb.NotFoundPage": "translation-fr.json",
		"com.a2z.it.kb.RelatedArticles": "translation-fr.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-fr.json",
		"com.a2z.it.kb.SearchBar": "translation-fr.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-fr.json",
		"com.a2z.it.kb.Sitemap": "translation-fr.json"
	},
	id: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-id.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-id.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-id.json",
		"com.a2z.it.kb.EditButton": "translation-id.json",
		"com.a2z.it.kb.EditedDate": "translation-id.json",
		"com.a2z.it.kb.Footer": "translation-id.json",
		"com.a2z.it.kb.Header": "translation-id.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-id.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-id.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-id.json",
		"com.a2z.it.kb.LanguagePicker": "translation-id.json",
		"com.a2z.it.kb.NotFoundPage": "translation-id.json",
		"com.a2z.it.kb.RelatedArticles": "translation-id.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-id.json",
		"com.a2z.it.kb.SearchBar": "translation-id.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-id.json",
		"com.a2z.it.kb.Sitemap": "translation-id.json"
	},
	"id-ID": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-id.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-id.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-id.json",
		"com.a2z.it.kb.EditButton": "translation-id.json",
		"com.a2z.it.kb.EditedDate": "translation-id.json",
		"com.a2z.it.kb.Footer": "translation-id.json",
		"com.a2z.it.kb.Header": "translation-id.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-id.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-id.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-id.json",
		"com.a2z.it.kb.LanguagePicker": "translation-id.json",
		"com.a2z.it.kb.NotFoundPage": "translation-id.json",
		"com.a2z.it.kb.RelatedArticles": "translation-id.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-id.json",
		"com.a2z.it.kb.SearchBar": "translation-id.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-id.json",
		"com.a2z.it.kb.Sitemap": "translation-id.json"
	},
	it: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-it.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-it.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-it.json",
		"com.a2z.it.kb.EditButton": "translation-it.json",
		"com.a2z.it.kb.EditedDate": "translation-it.json",
		"com.a2z.it.kb.Footer": "translation-it.json",
		"com.a2z.it.kb.Header": "translation-it.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-it.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-it.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-it.json",
		"com.a2z.it.kb.LanguagePicker": "translation-it.json",
		"com.a2z.it.kb.NotFoundPage": "translation-it.json",
		"com.a2z.it.kb.RelatedArticles": "translation-it.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-it.json",
		"com.a2z.it.kb.SearchBar": "translation-it.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-it.json",
		"com.a2z.it.kb.Sitemap": "translation-it.json"
	},
	"it-IT": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-it.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-it.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-it.json",
		"com.a2z.it.kb.EditButton": "translation-it.json",
		"com.a2z.it.kb.EditedDate": "translation-it.json",
		"com.a2z.it.kb.Footer": "translation-it.json",
		"com.a2z.it.kb.Header": "translation-it.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-it.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-it.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-it.json",
		"com.a2z.it.kb.LanguagePicker": "translation-it.json",
		"com.a2z.it.kb.NotFoundPage": "translation-it.json",
		"com.a2z.it.kb.RelatedArticles": "translation-it.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-it.json",
		"com.a2z.it.kb.SearchBar": "translation-it.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-it.json",
		"com.a2z.it.kb.Sitemap": "translation-it.json"
	},
	ja: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-ja.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-ja.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-ja.json",
		"com.a2z.it.kb.EditButton": "translation-ja.json",
		"com.a2z.it.kb.EditedDate": "translation-ja.json",
		"com.a2z.it.kb.Footer": "translation-ja.json",
		"com.a2z.it.kb.Header": "translation-ja.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-ja.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-ja.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-ja.json",
		"com.a2z.it.kb.LanguagePicker": "translation-ja.json",
		"com.a2z.it.kb.NotFoundPage": "translation-ja.json",
		"com.a2z.it.kb.RelatedArticles": "translation-ja.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-ja.json",
		"com.a2z.it.kb.SearchBar": "translation-ja.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-ja.json",
		"com.a2z.it.kb.Sitemap": "translation-ja.json"
	},
	"ja-JP": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-ja.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-ja.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-ja.json",
		"com.a2z.it.kb.EditButton": "translation-ja.json",
		"com.a2z.it.kb.EditedDate": "translation-ja.json",
		"com.a2z.it.kb.Footer": "translation-ja.json",
		"com.a2z.it.kb.Header": "translation-ja.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-ja.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-ja.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-ja.json",
		"com.a2z.it.kb.LanguagePicker": "translation-ja.json",
		"com.a2z.it.kb.NotFoundPage": "translation-ja.json",
		"com.a2z.it.kb.RelatedArticles": "translation-ja.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-ja.json",
		"com.a2z.it.kb.SearchBar": "translation-ja.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-ja.json",
		"com.a2z.it.kb.Sitemap": "translation-ja.json"
	},
	ko: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-ko.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-ko.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-ko.json",
		"com.a2z.it.kb.EditButton": "translation-ko.json",
		"com.a2z.it.kb.EditedDate": "translation-ko.json",
		"com.a2z.it.kb.Footer": "translation-ko.json",
		"com.a2z.it.kb.Header": "translation-ko.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-ko.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-ko.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-ko.json",
		"com.a2z.it.kb.LanguagePicker": "translation-ko.json",
		"com.a2z.it.kb.NotFoundPage": "translation-ko.json",
		"com.a2z.it.kb.RelatedArticles": "translation-ko.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-ko.json",
		"com.a2z.it.kb.SearchBar": "translation-ko.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-ko.json",
		"com.a2z.it.kb.Sitemap": "translation-ko.json"
	},
	"ko-KR": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-ko.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-ko.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-ko.json",
		"com.a2z.it.kb.EditButton": "translation-ko.json",
		"com.a2z.it.kb.EditedDate": "translation-ko.json",
		"com.a2z.it.kb.Footer": "translation-ko.json",
		"com.a2z.it.kb.Header": "translation-ko.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-ko.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-ko.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-ko.json",
		"com.a2z.it.kb.LanguagePicker": "translation-ko.json",
		"com.a2z.it.kb.NotFoundPage": "translation-ko.json",
		"com.a2z.it.kb.RelatedArticles": "translation-ko.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-ko.json",
		"com.a2z.it.kb.SearchBar": "translation-ko.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-ko.json",
		"com.a2z.it.kb.Sitemap": "translation-ko.json"
	},
	nl: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-nl.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-nl.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-nl.json",
		"com.a2z.it.kb.EditButton": "translation-nl.json",
		"com.a2z.it.kb.EditedDate": "translation-nl.json",
		"com.a2z.it.kb.Footer": "translation-nl.json",
		"com.a2z.it.kb.Header": "translation-nl.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-nl.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-nl.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-nl.json",
		"com.a2z.it.kb.LanguagePicker": "translation-nl.json",
		"com.a2z.it.kb.NotFoundPage": "translation-nl.json",
		"com.a2z.it.kb.RelatedArticles": "translation-nl.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-nl.json",
		"com.a2z.it.kb.SearchBar": "translation-nl.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-nl.json",
		"com.a2z.it.kb.Sitemap": "translation-nl.json"
	},
	"nl-NL": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-nl.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-nl.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-nl.json",
		"com.a2z.it.kb.EditButton": "translation-nl.json",
		"com.a2z.it.kb.EditedDate": "translation-nl.json",
		"com.a2z.it.kb.Footer": "translation-nl.json",
		"com.a2z.it.kb.Header": "translation-nl.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-nl.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-nl.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-nl.json",
		"com.a2z.it.kb.LanguagePicker": "translation-nl.json",
		"com.a2z.it.kb.NotFoundPage": "translation-nl.json",
		"com.a2z.it.kb.RelatedArticles": "translation-nl.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-nl.json",
		"com.a2z.it.kb.SearchBar": "translation-nl.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-nl.json",
		"com.a2z.it.kb.Sitemap": "translation-nl.json"
	},
	pl: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-pl.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-pl.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-pl.json",
		"com.a2z.it.kb.EditButton": "translation-pl.json",
		"com.a2z.it.kb.EditedDate": "translation-pl.json",
		"com.a2z.it.kb.Footer": "translation-pl.json",
		"com.a2z.it.kb.Header": "translation-pl.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-pl.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-pl.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-pl.json",
		"com.a2z.it.kb.LanguagePicker": "translation-pl.json",
		"com.a2z.it.kb.NotFoundPage": "translation-pl.json",
		"com.a2z.it.kb.RelatedArticles": "translation-pl.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-pl.json",
		"com.a2z.it.kb.SearchBar": "translation-pl.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-pl.json",
		"com.a2z.it.kb.Sitemap": "translation-pl.json"
	},
	"pl-PL": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-pl.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-pl.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-pl.json",
		"com.a2z.it.kb.EditButton": "translation-pl.json",
		"com.a2z.it.kb.EditedDate": "translation-pl.json",
		"com.a2z.it.kb.Footer": "translation-pl.json",
		"com.a2z.it.kb.Header": "translation-pl.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-pl.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-pl.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-pl.json",
		"com.a2z.it.kb.LanguagePicker": "translation-pl.json",
		"com.a2z.it.kb.NotFoundPage": "translation-pl.json",
		"com.a2z.it.kb.RelatedArticles": "translation-pl.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-pl.json",
		"com.a2z.it.kb.SearchBar": "translation-pl.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-pl.json",
		"com.a2z.it.kb.Sitemap": "translation-pl.json"
	},
	pt: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-pt.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-pt.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-pt.json",
		"com.a2z.it.kb.EditButton": "translation-pt.json",
		"com.a2z.it.kb.EditedDate": "translation-pt.json",
		"com.a2z.it.kb.Footer": "translation-pt.json",
		"com.a2z.it.kb.Header": "translation-pt.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-pt.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-pt.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-pt.json",
		"com.a2z.it.kb.LanguagePicker": "translation-pt.json",
		"com.a2z.it.kb.NotFoundPage": "translation-pt.json",
		"com.a2z.it.kb.RelatedArticles": "translation-pt.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-pt.json",
		"com.a2z.it.kb.SearchBar": "translation-pt.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-pt.json",
		"com.a2z.it.kb.Sitemap": "translation-pt.json"
	},
	"pt-BR": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-pt.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-pt.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-pt.json",
		"com.a2z.it.kb.EditButton": "translation-pt.json",
		"com.a2z.it.kb.EditedDate": "translation-pt.json",
		"com.a2z.it.kb.Footer": "translation-pt.json",
		"com.a2z.it.kb.Header": "translation-pt.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-pt.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-pt.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-pt.json",
		"com.a2z.it.kb.LanguagePicker": "translation-pt.json",
		"com.a2z.it.kb.NotFoundPage": "translation-pt.json",
		"com.a2z.it.kb.RelatedArticles": "translation-pt.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-pt.json",
		"com.a2z.it.kb.SearchBar": "translation-pt.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-pt.json",
		"com.a2z.it.kb.Sitemap": "translation-pt.json"
	},
	ro: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-ro.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-ro.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-ro.json",
		"com.a2z.it.kb.EditButton": "translation-ro.json",
		"com.a2z.it.kb.EditedDate": "translation-ro.json",
		"com.a2z.it.kb.Footer": "translation-ro.json",
		"com.a2z.it.kb.Header": "translation-ro.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-ro.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-ro.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-ro.json",
		"com.a2z.it.kb.LanguagePicker": "translation-ro.json",
		"com.a2z.it.kb.NotFoundPage": "translation-ro.json",
		"com.a2z.it.kb.RelatedArticles": "translation-ro.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-ro.json",
		"com.a2z.it.kb.SearchBar": "translation-ro.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-ro.json",
		"com.a2z.it.kb.Sitemap": "translation-ro.json"
	},
	"ro-RO": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-ro.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-ro.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-ro.json",
		"com.a2z.it.kb.EditButton": "translation-ro.json",
		"com.a2z.it.kb.EditedDate": "translation-ro.json",
		"com.a2z.it.kb.Footer": "translation-ro.json",
		"com.a2z.it.kb.Header": "translation-ro.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-ro.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-ro.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-ro.json",
		"com.a2z.it.kb.LanguagePicker": "translation-ro.json",
		"com.a2z.it.kb.NotFoundPage": "translation-ro.json",
		"com.a2z.it.kb.RelatedArticles": "translation-ro.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-ro.json",
		"com.a2z.it.kb.SearchBar": "translation-ro.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-ro.json",
		"com.a2z.it.kb.Sitemap": "translation-ro.json"
	},
	sk: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-sk.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-sk.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-sk.json",
		"com.a2z.it.kb.EditButton": "translation-sk.json",
		"com.a2z.it.kb.EditedDate": "translation-sk.json",
		"com.a2z.it.kb.Footer": "translation-sk.json",
		"com.a2z.it.kb.Header": "translation-sk.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-sk.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-sk.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-sk.json",
		"com.a2z.it.kb.LanguagePicker": "translation-sk.json",
		"com.a2z.it.kb.NotFoundPage": "translation-sk.json",
		"com.a2z.it.kb.RelatedArticles": "translation-sk.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-sk.json",
		"com.a2z.it.kb.SearchBar": "translation-sk.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-sk.json",
		"com.a2z.it.kb.Sitemap": "translation-sk.json"
	},
	"sk-SK": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-sk.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-sk.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-sk.json",
		"com.a2z.it.kb.EditButton": "translation-sk.json",
		"com.a2z.it.kb.EditedDate": "translation-sk.json",
		"com.a2z.it.kb.Footer": "translation-sk.json",
		"com.a2z.it.kb.Header": "translation-sk.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-sk.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-sk.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-sk.json",
		"com.a2z.it.kb.LanguagePicker": "translation-sk.json",
		"com.a2z.it.kb.NotFoundPage": "translation-sk.json",
		"com.a2z.it.kb.RelatedArticles": "translation-sk.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-sk.json",
		"com.a2z.it.kb.SearchBar": "translation-sk.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-sk.json",
		"com.a2z.it.kb.Sitemap": "translation-sk.json"
	},
	und: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation.json",
		"com.a2z.it.kb.ArticlesByDate": "translation.json",
		"com.a2z.it.kb.EditButton": "translation.json",
		"com.a2z.it.kb.EditedDate": "translation.json",
		"com.a2z.it.kb.Footer": "translation.json",
		"com.a2z.it.kb.Header": "translation.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation.json",
		"com.a2z.it.kb.LanguagePicker": "translation.json",
		"com.a2z.it.kb.NotFoundPage": "translation.json",
		"com.a2z.it.kb.RelatedArticles": "translation.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation.json",
		"com.a2z.it.kb.SearchBar": "translation.json",
		"com.a2z.it.kb.SearchResultsPage": "translation.json",
		"com.a2z.it.kb.Sitemap": "translation.json"
	},
	vi: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-vi.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-vi.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-vi.json",
		"com.a2z.it.kb.EditButton": "translation-vi.json",
		"com.a2z.it.kb.EditedDate": "translation-vi.json",
		"com.a2z.it.kb.Footer": "translation-vi.json",
		"com.a2z.it.kb.Header": "translation-vi.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-vi.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-vi.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-vi.json",
		"com.a2z.it.kb.LanguagePicker": "translation-vi.json",
		"com.a2z.it.kb.NotFoundPage": "translation-vi.json",
		"com.a2z.it.kb.RelatedArticles": "translation-vi.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-vi.json",
		"com.a2z.it.kb.SearchBar": "translation-vi.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-vi.json",
		"com.a2z.it.kb.Sitemap": "translation-vi.json"
	},
	"vi-VN": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-vi.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-vi.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-vi.json",
		"com.a2z.it.kb.EditButton": "translation-vi.json",
		"com.a2z.it.kb.EditedDate": "translation-vi.json",
		"com.a2z.it.kb.Footer": "translation-vi.json",
		"com.a2z.it.kb.Header": "translation-vi.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-vi.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-vi.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-vi.json",
		"com.a2z.it.kb.LanguagePicker": "translation-vi.json",
		"com.a2z.it.kb.NotFoundPage": "translation-vi.json",
		"com.a2z.it.kb.RelatedArticles": "translation-vi.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-vi.json",
		"com.a2z.it.kb.SearchBar": "translation-vi.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-vi.json",
		"com.a2z.it.kb.Sitemap": "translation-vi.json"
	},
	zh: {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-zh.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-zh.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-zh.json",
		"com.a2z.it.kb.EditButton": "translation-zh.json",
		"com.a2z.it.kb.EditedDate": "translation-zh.json",
		"com.a2z.it.kb.Footer": "translation-zh.json",
		"com.a2z.it.kb.Header": "translation-zh.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-zh.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-zh.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-zh.json",
		"com.a2z.it.kb.LanguagePicker": "translation-zh.json",
		"com.a2z.it.kb.NotFoundPage": "translation-zh.json",
		"com.a2z.it.kb.RelatedArticles": "translation-zh.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-zh.json",
		"com.a2z.it.kb.SearchBar": "translation-zh.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-zh.json",
		"com.a2z.it.kb.Sitemap": "translation-zh.json"
	},
	"zh-CN": {
		"com.a2z.it.kb.ArticleFeedbackWidget": "translation-zh.json",
		"com.a2z.it.kb.ArticlesByCategory": "translation-zh.json",
		"com.a2z.it.kb.ArticlesByDate": "translation-zh.json",
		"com.a2z.it.kb.EditButton": "translation-zh.json",
		"com.a2z.it.kb.EditedDate": "translation-zh.json",
		"com.a2z.it.kb.Footer": "translation-zh.json",
		"com.a2z.it.kb.Header": "translation-zh.json",
		"com.a2z.it.kb.InternalServerErrorPage": "translation-zh.json",
		"com.a2z.it.kb.LandingPageNewArticles": "translation-zh.json",
		"com.a2z.it.kb.LandingPageRecentlyUpdated": "translation-zh.json",
		"com.a2z.it.kb.LanguagePicker": "translation-zh.json",
		"com.a2z.it.kb.NotFoundPage": "translation-zh.json",
		"com.a2z.it.kb.RelatedArticles": "translation-zh.json",
		"com.a2z.it.kb.RequestUpdateButton": "translation-zh.json",
		"com.a2z.it.kb.SearchBar": "translation-zh.json",
		"com.a2z.it.kb.SearchResultsPage": "translation-zh.json",
		"com.a2z.it.kb.Sitemap": "translation-zh.json"
	}
};
var arbManifest = {
	endpointMap: endpointMap
};

export { arbManifest as default, endpointMap };
