import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import React from "react";

interface RequestUpdateButtonProps extends WithBundleProps {}

const RequestUpdateButton = (props: RequestUpdateButtonProps) => {
  const { bundle } = props;

  return (
    <a
      target="_blank"
      className="header-button"
      href="https://w.amazon.com/bin/view/ITservices/Content/ITSE-KB/Intake/Update_Existing_Article/"
      rel="noreferrer"
    >
      {bundle.getMessage("request-update")}
    </a>
  );
};

export default withBundle("com.a2z.it.kb.RequestUpdateButton")(RequestUpdateButton);
