import "./style.scss";

import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import React from "react";
import { Link } from "react-router-dom";

interface FooterProps extends WithBundleProps {}

const Footer = (props: FooterProps) => {
  const { bundle } = props;

  return (
    <div className="kb-footer">
      <div className="footer-content">
        <a
          href="https://w.amazon.com/bin/view/ITservices/Content/ITSE-KB/Intake/Update_Existing_Article"
          target="_blank"
          rel="noopener noreferrer"
        >
          {bundle.getMessage("request-update")}
        </a>
        <Link to="/sitemap">{bundle.getMessage("sitemap")}</Link>
      </div>
    </div>
  );
};

export default withBundle("com.a2z.it.kb.Footer")(Footer);
