import { ContentRenderingConfiguration, LegoPage, Stage } from "@amzn/its-content-rendering";
import { DEFAULT_LOCALE, LOCALE_KEY } from "config/constants";
import ClickstreamHelper from "lib/helpers/clickstream-helper";
import React from "react";

import { useAppContext } from "../../../AppContext";
import UrlHelper from "../../../helpers/url-helper";

interface ILegoPageProps {
  path?: string;
  html?: string;
}

const StencilizedLegoPage = (props: ILegoPageProps) => {
  const { clickStreamUrls } = useAppContext();
  const language: string = window.localStorage.getItem(LOCALE_KEY) || DEFAULT_LOCALE;
  const path = props.path ?? window.location.pathname + window.location.search;
  // The http client class used by the Clickstream help requires this function to be bound when passing as prop
  const createFeatureMetricWithBind = ClickstreamHelper.createFeatureMetric.bind(ClickstreamHelper);

  ContentRenderingConfiguration.initialize({ site: "KB", stage: UrlHelper.getStage().toUpperCase() as Stage });

  return (
    <LegoPage
      language={language}
      path={path}
      html={props.html}
      clickStreamUrls={clickStreamUrls}
      createFeatureMetric={createFeatureMetricWithBind}
      withStencilProvider
    />
  );
};

export default StencilizedLegoPage;
