import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import { Container, Header } from "common/components";
import React from "react";

interface InternalServerErrorPageProps extends WithBundleProps {
  message?: string;
}

const InternalServerErrorPage = ({ bundle, message }: InternalServerErrorPageProps) => {
  const header = <Header variant="h2">{bundle.getMessage("error-header-text")}</Header>;
  return <Container header={header}>{message || bundle.getMessage("default-error-message")}</Container>;
};

export default withBundle("com.a2z.it.kb.InternalServerErrorPage")(InternalServerErrorPage);
