import { ANNOUNCEMENT_PAGE_SLUG, LANDING_PAGE_SLUG } from "../config/constants";
import { IArticleMetadata } from "../lib/interfaces/metadata";

/** Checks if the article metadata is valid
 * A valid article will have:
 * - a non-empty title
 * - a non-empty url that's not 'landing-page' or 'its-announcements'
 */
export const isValidArticleMetadata = (article: IArticleMetadata) =>
  article.title &&
  article.full_path &&
  !article.full_path.endsWith(LANDING_PAGE_SLUG) &&
  !article.full_path.endsWith(ANNOUNCEMENT_PAGE_SLUG);
