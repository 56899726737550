import "./style.scss";

import { withBundle, WithBundleProps } from "@amzn/react-arb-tools";
import { Header } from "common/components";
import { DEFAULT_LOCALE, LOCALE_KEY, MAIN_PAGE_TITLE, METADATA_ARTICLES_FILTERED_PATH } from "config/constants";
import { isValidArticleMetadata } from "helpers/metadata-helper";
import UrlHelper from "helpers/url-helper";
import HttpClient from "lib/clients/HttpClient";
import LoadingScreen from "modules/components/LoadingScreen";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { IArticleMetadata } from "../../../lib/interfaces/metadata";
import InternalServerErrorPage from "../InternalServerError";
import ArticleItem from "./ArticleItem";

interface ArticlesByDateProps extends WithBundleProps {
  filter: "created_at" | "updated_at";
}

const ArticlesByDate = (props: ArticlesByDateProps) => {
  const { bundle } = props;
  const [articles, setArticles] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const language: string = window.localStorage.getItem(LOCALE_KEY) || DEFAULT_LOCALE;
  const titleText = bundle.getMessage(
    props.filter === "created_at" ? "new-articles-page-title" : "recently-updated-articles-page-title"
  );

  const fetchArticles = async () => {
    const apiUrl = UrlHelper.getApiUrl(UrlHelper.getStage());
    // eslint-disable-next-line max-len
    const metadataRetrievalUrl = `${apiUrl}${METADATA_ARTICLES_FILTERED_PATH}?filter=${props.filter}&max-articles=50&language=${language}`;

    try {
      const response = await HttpClient.getData(metadataRetrievalUrl);
      const data = await response.json();

      if (data.errorMessage) {
        throw new Error(data.errorMessage);
      }
      if (data) {
        setArticles(data);
        setIsError(false);
      }
    } catch (error) {
      setArticles([]);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchArticles();
  }, []);

  if (isLoading) {
    return <LoadingScreen show={true} />;
  }

  if (isError) {
    return <InternalServerErrorPage />;
  }

  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href={UrlHelper.getLegoStylesUrl()} />
        <title>{`${titleText} - ${MAIN_PAGE_TITLE}`}</title>
      </Helmet>

      <div className="article-list">
        <Header>{titleText}</Header>
        <br />
        {articles.map(
          (article: IArticleMetadata, index: number) =>
            isValidArticleMetadata(article) && <ArticleItem {...article} key={index} />
        )}
      </div>
    </>
  );
};

export default withBundle("com.a2z.it.kb.ArticlesByDate")(ArticlesByDate);
